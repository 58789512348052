<template>
  <div>
    <h1 class="title is-2 has-text-centered">User Subscription Management</h1>
    <base-loader v-if="dataLoading"></base-loader>
    <section v-else>
      <div class="searchbar is-flex is-justify-content-center">
        <div class="field">
          <p class="control has-icons-left">
            <input class="input" type="text" placeholder="search by display name" v-model="search">
            <span class="icon is-small is-left">
              <i class="fas fa-search"></i>
            </span>
          </p>
        </div>
      </div>
      <table class="table is-striped"
        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
        detailed
        detail-key="oid"
        :show-detail-icon="showDetailIcon">
        <thead>
          <th></th>
          <th class="has-text-left">OID</th>
          <th class="has-text-left">Display Name</th>
          <th class="has-text-left">Principal</th>
          <th class="has-text-left">Role</th>
          <th class="has-text-left">Subs</th>
        </thead>

        <tbody v-for="user in users" :key="user.oid">
          <tr>
            <td>
              <div class="expander-trigger"
              :id="user.oid"
              @click="toggle($event, user)"
              :class="open?'active':'beforeBorder'">
              <i class="fas" :class="{'fa-chevron-down': open && userId === user.oid, 'fa-chevron-right': !open || userId !== user.oid}"></i>
              </div>
            </td>
            <td class="has-text-left" field="oid" label="OID" sortable>{{ user.oid }}</td>
            <td class="has-text-left" field="displayName" label="Display Name">{{ user.displayName }}</td>
            <td class="has-text-left abridged" field="userPrincipalName" label="Principal" sortable>{{ user.userPrincipalName }}</td>
            <td class="has-text-left" style="padding-left: 0.25em" field="role" label="Role" sortable>{{ user.role }}</td>
            <td class="has-text-centered" field="subscriptions" label="Subscriptions" sortable>
              <i :class="user.subscriptions && user.subscriptions.length > 0 ? 'fas fa-check-circle' : 'far fa-times-circle'"></i>
            </td>
          </tr>

          <!-- collapsible row -->
          <tr v-show="open && userId === user.oid">
            <td colspan="6">
              <div class="heading mt-4">
                <h2 class="subtitle">Subscriptions</h2>
                <button class="button" @click="modalSubscription(user)"><i class="fas fa-plus-square"></i></button>
              </div>
              <table class="table is-striped mt-4 mb-4" v-if="user.subscriptions.length > 0">
                <thead class="headings">
                  <th>Product Name</th>
                  <th>Total Tokens</th>
                  <th>Tokens Used</th>
                  <th>Tokens Available</th>
                  <th>Status</th>
                  <th>Expiration Date</th>
                  <th>Operations</th>
                </thead>
                <tbody>
                  <tr v-for="(subscription, id) in user.subscriptions" :key="id">
                    <td>{{ formatProductName(subscription.product_name) }}</td>
                    <td>{{ subscription.total_tokens }}</td>
                    <td>{{ subscription.tokens_used }}</td>
                    <td>{{ subscription.tokens_available }}</td>
                    <td>{{ formatStatus(subscription.status) }}</td>
                    <td>{{ new Date(subscription.expiration_datetime).toLocaleDateString() }}</td>
                    <td class="is-flex is-justify-content-center">
                      <button class="button is-link is-light" @click="modalSubscription(user, subscription)"><i class="fas fa-user-edit"></i></button>
                      &nbsp;
                      <button class="button is-link is-light" @click="deleteSubscription(user, subscription)"><i class="fas fa-trash"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>


      <!--pagination-->
      <div class="is-flex is-justify-content-flex-end">
        <button class="button mr-2" :disabled="page === 1" @click="onPageChange(page - 1)"> prev </button>
        <nav class="pagination" role="navigation" aria-label="pagination">
          <ul class="pagination-list" v-for="number in pageNumbers" :key="number">
            <li>
              <a
                class="pagination-link"
                :class="{'is-current': number == page}"
                aria-label="Page 1"
                aria-current="page"
                @click="onPageChange(number)">
                {{number}}
              </a>
            </li>
          </ul>
        </nav>
        <button class="button ml-2" :disabled="page === pageNumbers.length" @click="onPageChange(page + 1)"> next </button>
      </div>

      <!--Modal-->
      <base-modal v-if="isComponentModalActive" @close="closeModal" @addSubscription="addSubscription" @editSubscription="editSubscription" :user="formProps.userProp" :subscriptionProp="formProps.subscriptionProp" :isNew="formProps.isNew" :title="formProps.title" :products="products"
      ></base-modal>
    </section>

    <!--Update users' button-->
    <button class="button is-primary" @click="updateUsers()" v-if="!loading">
      <i class="fas fa-sync-alt"></i>
      <span class="ml-2">Update Users (from Azure)</span>
    </button>
    <base-loader v-else></base-loader>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import gql from 'graphql-tag';
import BaseLoader from '../components/BaseLoader.vue';
import BaseModal from '../components/BaseModal.vue';

const products = [
  {
    id: 'cipa',
    label: 'CiPA In Silico',
  },
  {
    id: 'diflomotecan',
    label: 'Diflomotecan',
  },
  {
    id: 'gnrh',
    label: 'GnRH',
  },
  {
    id: 'mstreat',
    label: 'MS Treat Sim',
  },
  {
    id: 'numris',
    label: 'NuMRis',
  },
  {
    id: 'qttdp',
    label: 'QT/TdP Risk Screen',
  },
  {
    id: 'sev',
    label: 'SEV',
  },
  {
    id: 'strhips',
    label: 'STRhiPS',
  },
  {
    id: 'tabletcoater',
    label: 'SimTabletCoater',
  },
  {
    id: 'triptorelin',
    label: 'Triptorelin',
  },
];


export default {
  name: 'users',
  components: {
    BaseLoader,
    BaseModal,
  },
  data() {
    return {
      open: false,
      userId: null,
      data: [],
      search: '',
      awaitingSearch: false,
      total: 0,
      showDetailIcon: true,
      loading: false,
      dataLoading: false,
      sortField: 'oid',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      page: 1,
      perPage: 20,
      isComponentModalActive: false,
      formProps: {
        title: 'Title',
        isNew: false,
        userProp: {},
        subscriptionProp: {},
      },
    };
  },
  computed: {
    ...mapState('iam', {
      user: (state) => state.user,
    }),
    products() {
      return products;
    },
    searchedTable() {
      const searchedTable = this.data.filter((el) => el.displayName.toLowerCase().includes(this.search));
      return searchedTable;
    },
    dataPerPage() {
      const idLast = this.page * this.perPage;
      const idFirst = idLast - this.perPage;
      const dataPerPage = this.data.slice(idFirst, idLast);
      return dataPerPage;
    },
    pageNumbers() {
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(this.searchedTable.length / this.perPage); i += 1) {
        pageNumbers.push(i);
      }
      return pageNumbers;
    },

    users() {
      let users = [];
      if (this.search === '') {
        users = this.dataPerPage;
      } else {
        users = this.searchedTable;
      }
      return users;
    },

  },
  watch: {
    search() {
      const debounceMilliSeconds = 300;
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.searchedTable();
          this.awaitingSearch = false;
        }, debounceMilliSeconds);
      }
      this.awaitingSearch = true;
    },
  },

  methods: {
    toggle(event, user) {
      if (event.currentTarget.id === user.oid) {
        this.userId = event.currentTarget.id;
        this.open = !this.open;
      } else {
        this.open = false;
      }
    },
    /*
     * Table formatters
     */
    formatProductName(product_name) {
      const product = this.products.find((element) => element.id === product_name);
      return product.label;
    },
    formatStatus(status) {
      let text = null;
      switch (status) {
        case 'active':
          text = 'Active';
          break;
        case 'expired':
          text = 'Expired';
          break;
        default:
          text = status;
      }
      return text;
    },
    /*
     * Load async users
     */
    async loadUsers(users = null) {
      try {
        this.dataLoading = true;
        let data = users;
        if (users == null) {
          const response = await this.$apollo.query({
            query: gql`
            query getUsers {
              users: getUsers {
                oid
                displayName
                userPrincipalName
                role
                subscriptions {
                  product_name
                  total_tokens
                  tokens_used
                  tokens_available
                  status
                  expiration_datetime
                }
              }
            }`,
          });
          data = response.data.users;
        }
        this.data = data;
        this.total = data.length;
        this.dataLoading = false;
      } catch (error) {
        this.$utils.alertError(error);
      }
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
    /* handle adding or editing subscription */
    modalSubscription(user, subscription) {
      this.isComponentModalActive = true;
      this.formProps.userProp = user;
      if (subscription) {
        // Edit subscription
        this.formProps.title = 'Edit subscription';
        this.formProps.isNew = false;
        if (subscription.expiration_datetime) {
          const date = new Date(subscription.expiration_datetime);
          subscription.expiration_datetime = JSON.stringify(date).slice(1, 11); // changing the data format
        }
        this.formProps.subscriptionProp = subscription;
      } else {
        // Add new subscription
        this.formProps.title = 'Add new subscription';
        this.formProps.isNew = true;
        const date = new Date();
        date.setMonth(date.getMonth() + 1); // + 1 month
        this.formProps.subscriptionProp = {
          product_name: this.products[0].id,
          total_tokens: 100,
          tokens_used: 0,
          tokens_available: 100,
          status: 'active',
          expiration_datetime: JSON.stringify(date).slice(1, 11), // changing the data format
        };
      }
    },

    /*
     * Mutuations
    */
    async addSubscription(user, subscription) {
      try {
        this.loading = true;
        const response = await this.$apollo.mutate({
          mutation: gql`
          mutation addUserSubscription($oid: ID!, $subscription: UserSubscriptionIn!) {
            subscription: addUserSubscription(oid: $oid, subscription: $subscription) {
              product_name
              total_tokens
              tokens_used
              tokens_available
              status
              expiration_datetime
            }
          }`,
          variables: {
            oid: user.oid,
            subscription,
          },
          // TODO update apollo cache
        });
        const data = response.data.subscription;
        user.subscriptions.push(data);
        this.loading = false;
      } catch (error) {
        this.$utils.alertError(error);
      }
    },
    async editSubscription(user, subscriptionOrig, subscription) {
      try {
        this.loading = true;
        delete subscriptionOrig.__typename;
        delete subscription.__typename;
        const response = await this.$apollo.mutate({
          mutation: gql`
          mutation editUserSubscription($oid: ID!, $subscriptionOrig: UserSubscriptionIn!, $subscription: UserSubscriptionIn!) {
            subscription: editUserSubscription(oid: $oid, subscriptionOrig: $subscriptionOrig, subscription: $subscription) {
              product_name
              total_tokens
              tokens_used
              tokens_available
              status
              expiration_datetime
            }
          }`,
          variables: {
            oid: user.oid,
            subscriptionOrig,
            subscription,
          },
          // TODO update apollo cache
        });
        const data = response.data.subscription;
        user.subscriptions.forEach((element, index, array) => {
          if (element.product_name === subscriptionOrig.product_name) {
            array.splice(index, 1);
          }
        });
        user.subscriptions.push(subscription);
        this.loading = false;
      } catch (error) {
        this.$utils.alertError(error);
      }
    },
    async deleteSubscription(user, subscription) {
      try {
        this.loading = true;
        delete subscription.__typename;
        const response = await this.$apollo.mutate({
          mutation: gql`
          mutation deleteUserSubscription($oid: ID!, $subscription: UserSubscriptionIn!) {
            subscription: deleteUserSubscription(oid: $oid, subscription: $subscription) {
              product_name
            }
          }`,
          variables: {
            oid: user.oid,
            subscription,
          },
          // TODO update apollo cache
        });
        const data = response.data.subscription;
        user.subscriptions.forEach((element, index, array) => {
          if (element.product_name === subscription.product_name) {
            array.splice(index, 1);
          }
        });
        this.loading = false;
      } catch (error) {
        this.$utils.alertError(error);
      }
    },
    closeModal() {
      this.isComponentModalActive = !this.isComponentModalActive;
    },
    async updateUsers() {
      try {
        this.loading = true;
        const response = await this.$apollo.mutate({
          mutation: gql`
          mutation updateUsers {
            users: updateUsers {
              oid
              displayName
              userPrincipalName
              role
              subscriptions {
                product_name
                total_tokens
                tokens_used
                tokens_available
                status
                expiration_datetime
              }
            }
          }`,
          // TODO update apollo cache
        });
        const data = response.data.users;
        this.loadUsers(data);
        this.loading = false;
      } catch (error) {
        this.$utils.alertError(error);
      }
    },
  },
  async created() {
    this.loadUsers();
  },
};
</script>

<style lang="scss" scoped>
span {
  &.select {
    width: 100%;
  }
  select {
    width: 100%;
  }
}
.searchbar {
  margin-top: 2em;
  margin-bottom: 3em;
}
.searchbar > input {
  width: 25em;
}
table {
width: 100%;
}
.headings{
  border-bottom: 1.4px solid #ebebeb;
}
.beforeBorder {
  position: relative;
}
.beforeBorder:not(:hover)::before {
  transform: scaleX(0);
  opacity: 0;
}
.expander-trigger {
  cursor: pointer;
}
.fa-chevron-down {
  color: #485fc7;
  transition: transform 0.2s cubic-bezier(0.23, 1, 0.32, 1);
}
th, td {
  padding-left: 0.1em;
  padding-right: 0.1em;
}
.abridged {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25em;
  white-space: nowrap;
}
</style>
