<template>
<div class="modal is-active">
    <div class="modal-background"></div>
    <form @submit.prevent="formSubmit">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title"> {{title}} </p>
        </header>
        <section class="modal-card-body">
          <div class="is-flex is-justify-content-space-evenly">
            <div class="field">
                <label class="label" expanded>OID</label>
                <input class="input is-link" v-model="user.oid" required disabled>
            </div>

            <div class="field">
              <label class="label" expanded>Product Name </label>
              <div class="select is-link">
                  <select v-model="subscription.product_name" required>
                <option v-for="prod in products" :key="prod.id" :value="prod.id">{{ prod.label }}</option>
              </select>
              </div>
            </div>
          </div>

          <div class="is-flex mt-4">
            <div class="field mr-2">
              <label class="label" expanded>Total Tokens</label>
              <input class="input" type="number" v-model.number="subscription.total_tokens" required>
            </div>

            <div class="field mr-2">
              <label class="label" expanded>Tokens Used</label>
              <input class="input" type="number" v-model.number="subscription.tokens_used" required>
            </div>

            <div class="field">
              <label class="label" expanded>Tokens Available</label>
              <input class="input" type="number" v-model.number="subscription.tokens_available" required>
            </div>
          </div>

          <div class="is-flex is-justify-content-space-evenly is-align-items-center">
            <div class="field">
                <label class="label" expanded>Status </label>
                <div class="select is-link">
                    <select v-model="subscription.status" required>
                      <option value="active">Active</option>
                      <option value="expired">Expired</option>
                </select>
                </div>
            </div>

            <div class="field">
                <label class="label" expanded>Expiration Datetime</label>
                <div class="control">
                <input class="input is-info" v-model="subscription.expiration_datetime" type="date" />
              </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-link is-outlined" type="button" @click="formClose">Close</button>
          <button class="button is-link" type="submit">Submit</button>
        </footer>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: ['user', 'subscriptionProp', 'isNew', 'title', 'products'],
  data() {
    return {
      subscription: { ...this.subscriptionProp },
    };
  },
  methods: {
    formClose() {
      this.$emit('close');
    },
    formSubmit() {
      if (this.isNew) {
        // Add new subscription
        this.$emit('addSubscription', this.user, this.subscription);
      } else {
        // Edit subscription
        this.$emit('editSubscription', this.user, this.subscriptionProp, this.subscription);
      }
      this.formClose();
    },
  },
  emits: ['addSubscription', 'editSubscription', 'close'],
};
</script>
